import React, { useMemo, useState } from "react"
import getValue from 'get-value'
import SEO from "../components/seo"
import ResourceList from "../components/resource-list"
import PageHeader from "../components/page-header"
import InstagramFeed from "@src/components/instagram-feed"
import Section from "@src/components/section"
import Pagination from "@components/pagination/index"
import { useSiteSettingsStore } from "@src/hooks/useStore"

const ResourcesIndex = ({ pageContext }) => {
  const document = getValue(pageContext, "pageEntry")[0]
  const categories = pageContext.categories
  const entries = pageContext.entries
  const { pageIntro, title: pageTitle, mainImage } = pageContext.pageEntry[0]

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 9;

  const totalPages = Math.ceil(entries.length / itemsPerPage)

  if ((currentPage - 1) * itemsPerPage > entries.length) {
    setCurrentPage(totalPages)
  }

  const {siteSettings} = useSiteSettingsStore(['siteSettings']);

  const instaPosts = siteSettings?.instaPosts;

  const resourceItemsToDisplay = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + +itemsPerPage

    return entries?.slice(startIndex, endIndex);
  }, [currentPage]);

  const onPageChange = newPage => {
    setCurrentPage(newPage)
  }

  const {
    activeCategorySlug,
  } = pageContext

  const documentData = {...document, blogCategory: activeCategorySlug}
  const subnavItems = [
    {
      title: "All",
      url: `/resources/`,
      key: `categoryLink-all`,
    },
    ...categories.map(category => {
      return {
        ...category,
        url: `/resources/${category.slug.current}/`,
        key: `categoryLink-${category._id}`,
      }
    }),
  ]

  return (
    <>
      <SEO {...{ document: documentData }} />
      <div className="mb-12">
        <PageHeader center title={pageTitle} intro={pageIntro} bgImage={mainImage} bgColor={'bg-[#F9F9F9]'} height='h-[325px]' items={subnavItems} activeSlug={activeCategorySlug}/>
      </div>

      {entries && (
        <Section>
          <div className="flex flex-col justify-center items-center w-full overflow-hidden">
            <ResourceList entries={resourceItemsToDisplay} />
            <Pagination 
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
            <InstagramFeed instaPosts={instaPosts} />
          </div>
        </Section>
      )}
    </>
  )
}

export default ResourcesIndex
